import React from 'react';

import './Login.css';
import { Button } from 'carbon-components-react';

const Login = (props) => {
  return (
    <div>
      <h6 className="text-center">Welcome</h6>
    </div>
  );
};

export default Login;
