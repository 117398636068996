import React, { Component, Suspense } from 'react';

//Router Dom
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

//Redux
import { connect } from 'react-redux';

//Common Components
import JDProcessOverlay from '../components/common/JDProcessOverlay';
import XPAErrorBoundary from '../containers/common/XPAErrorBoundary';
import { FlaskLoader } from '../components/common/common';

//Main Functional Components
import PublicAmpPlayer from '../components/public/PublicAmpPlayer';

//Main Components (UI)
import Login from '../components/public/Login';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <XPAErrorBoundary user={this.props.user}>
          <Suspense fallback={<FlaskLoader />}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route
                exact
                path={`/public/amp/player`}
                component={PublicAmpPlayer}
              />
            </Switch>
            <JDProcessOverlay
              show={this.state.isLogging}
              message="processing..."
            />
          </Suspense>
        </XPAErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
