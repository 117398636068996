import React from 'react';

import flaskLoaderImg from '../../assets/images/loader.svg';

const FlaskLoader = () => (
  <div className="xpa-loader-wrap">
    <img
      style={{ width: '100px', height: '100px' }}
      src={flaskLoaderImg}
      d={flaskLoaderImg}
      alt="Application Loader"
    />
  </div>
);

export { FlaskLoader };
